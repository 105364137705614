.service-page {
    background-color: #f9f9f9;
}
.service-odd-row{
    background-color: #1c88ed !important;
    padding: 20px;
    color: white !important;
}
.service-even-row{
    background-color: #ffffff !important;
    padding: 20px;
}
.hero-section {
    background-color: #7dc0ff;
    color: white;
    padding: 50px 20px;
    text-align: center;
    margin-bottom: 30px;
}

.hero-title {
    font-size: 2.0rem !important;
    color: white !important;
}

.hero-subtitle {
    font-size: 1.5rem;
    color: #ffffff !important;
    background-color: #1b89f1;
}

.content-section {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
}

.feature-list, .benefit-list {
    list-style: none;
    padding: 0;
    line-height: 1.8;
}

.feature-list li, .benefit-list li {
    padding: 10px 0;
}

.feature-card {
    background-color: #fafafa;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
}
.feature-title{
    font-size: 20px !important;
    font-weight: bold !important;
}
.feature-title-icon{
    font-size: 40px !important;
    color:black !important;
}
/* ServiceStyle.css */

/* Styles for hero section */
.hero-section {
    padding: 50px 0;
    text-align: center;
}

.hero-title {
    font-size: 2.5rem;
    margin-bottom: 16px;
}

.hero-subtitle {
    font-size: 1.5rem;
    color: #666;
}

/* Styles for content section */
.content-section {
    padding: 50px 0;
}

/* Styles for feature list */
.feature-list {
    list-style-type: none;
    padding-left: 0;
}

.feature-list li {
    margin-bottom: 12px;
    font-size: 1rem;
    line-height: 1.6;
}

/* Styles for feature cards */
.feature-card {
    padding: 24px;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.feature-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Media queries for responsiveness */
@media (max-width: 992px) {
    .hero-title {
        font-size: 2rem;
    }
    .hero-subtitle {
        font-size: 1.2rem;
    }
}
.topic-title{
    text-align: center !important;
    margin-top: 10px;
    color: white ;
}
@media (max-width: 768px) {
    .hero-title {
        font-size: 1.8rem;
    }
    .topic-title{
        font-size: 1.6rem !important;
        text-align: center !important;
        margin-top: 10px;
    }
    .hero-subtitle {
        font-size: 1rem;
    }
    .feature-card {
        padding: 16px;
    }

}

@media (max-width: 576px) {
    .hero-title {
        font-size: 1.5rem;
    }
    .hero-subtitle {
        font-size: 0.8rem;
    }
    .feature-card {
        padding: 12px;
    }
}
