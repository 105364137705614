

.odd-row, .even-row {
    margin: 20px 0;
    padding-left: 20px;
    padding-right: 20px;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .odd-row, .even-row {
        margin: 10px 0;
    }
    .homepage {
        padding : 0px;
    }
    h1 {
        font-size: 1.5em;
    }

    .industry-section {
        padding: 10px;
    }
}

.banner-item {
    position: relative;
    height: 300px; /* Adjust height as needed */
    background-size: cover;
    background-position: center;
}

.banner-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity (0.5 for 50% transparent black) */
}

.banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
}
