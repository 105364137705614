/* Basic layout and responsive design */
.layout {
  min-height: 100vh;
}

.site-layout-content {
  min-height: 280px;
}

@media (max-width: 768px) {
  .site-layout-content {
      padding: 12px;
  }
}
