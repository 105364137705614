.carousel-component {
    position: relative;
}

.carousel-slide {
    position: relative;
    text-align: center;
}

.carousel-slide img {
    width: 100%;
    height: auto;
    max-height: 600px;
    object-fit: cover;
}

.carousel-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.carousel-content h1 {
    font-size: 2em;
    margin: 0;
}

.carousel-content p {
    font-size: 1.2em;
    margin: 0;
}

/* Responsive styles */
@media (max-width: 768px) {
    .carousel-content h1 {
        font-size: 1.5em;
    }

    .carousel-content p {
        font-size: 1em;
    }

    .carousel-slide img {
        max-height: 300px;
    }
}
